











import { Vue, Component, Prop } from 'vue-property-decorator';

interface CountdownUnit {
	label: string;
	value: string;
	prevValue: string;
}

@Component({})
export default class CountdownFlip extends Vue {
	@Prop({ default: '' }) date!: string;

	/*@Watch('countdownUnits', { immediate: true, deep: true })
	onCountdownUnitsChange(newVal: any) {
		this.shouldFlip = true;
		this.$nextTick(() => {
			this.shouldFlip = false;
		});
	}*/

	countdownUnits: CountdownUnit[] = [];
	intervalId: number | null = null;
	shouldFlip = false;

	mounted() {
		this.initCountdown();
		this.intervalId = window.setInterval(() => this.updateCountdown(), 1000);
	}

	beforeUnmount() {
		if (this.intervalId) {
			clearInterval(this.intervalId);
		}
	}

	initCountdown() {
		const targetDate = new Date(this.date).getTime();
		const now = new Date().getTime();
		const remainingTime = Math.max(targetDate - now, 0);
		this.updateCountdownUnits(remainingTime);
	}

	updateCountdown() {
		const targetDate = new Date(this.date).getTime();
		const now = new Date().getTime();
		const remainingTime = Math.max(targetDate - now, 0);
		this.updateCountdownUnits(remainingTime);
	}

	updateCountdownUnits(remainingTime: number) {
		this.shouldFlip = true;
		const secondsInOneDay = 86400;
		const secondsInOneHour = 3600;
		const secondsInOneMinute = 60;
		console.log('called');

		const days = Math.floor(remainingTime / (secondsInOneDay * 1000));
		remainingTime -= days * secondsInOneDay * 1000;

		const hours = Math.floor(remainingTime / (secondsInOneHour * 1000));
		remainingTime -= hours * secondsInOneHour * 1000;

		const minutes = Math.floor(remainingTime / (secondsInOneMinute * 1000));
		remainingTime -= minutes * secondsInOneMinute * 1000;

		const seconds = Math.floor(remainingTime / 1000);

		this.countdownUnits = [
			{ label: 'days', value: this.formatValue(days), prevValue: this.formatValue(days + 1) },
			{ label: 'hours', value: this.formatValue(hours), prevValue: this.formatValue(hours + 1) },
			{ label: 'min', value: this.formatValue(minutes), prevValue: this.formatValue(minutes + 1) },
			{ label: 'sec', value: this.formatValue(seconds), prevValue: this.formatValue(seconds + 1) }
		];
		this.shouldFlip = false;
	}

	formatValue(value: number) {
		return value < 10 ? '0' + value : value.toString();
	}
}
